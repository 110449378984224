<template>
  <div class="container">
    <LocationAlert
      :locationId="+reservation.spot.locationId"
      :spotId="+reservation.spot.id"
      v-if="reservation.spot.locationId"
    />
    <div class="mx-auto">
      <div class="bread-crumb my-3">
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/').catch(() => {})"
          >HOME</a
        >
        >
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/parksearchresults').catch(() => {})"
          >SEARCH RESULTS</a
        >
        >
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="
            $router.push(`/location/${reservation.locationId}`).catch(() => {})
          "
          >{{ reservation.locationName.toUpperCase() }}</a
        >
        > CREATE RESERVATION
      </div>
      <ValidationObserver ref="createReservationForm">
        <form @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-md-8">
              <FormErrorAlert
                v-if="errors.length > 0"
                :errors="errors"
                :formRef="this.$refs.createReservationForm"
              />
              <div class="card">
                <div class="card-header">
                  <div class="card-title my-2">Personal Information</div>
                </div>
                <div class="card-body">
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <label for="userIsPrimary">Select Primary Occupant</label>
                      <select
                        class="form-control form-control-lg"
                        id="userIsPrimary"
                        v-model="userIsPrimary"
                      >
                        <option v-if="loggedInCustomer.lastName" :value="true">
                          {{ loggedInCustomer.firstName }}
                          {{ loggedInCustomer.lastName }}
                        </option>
                        <option :value="false">
                          Other
                        </option>
                      </select>
                    </div>
                  </div>
                  <div v-if="!userIsPrimary">
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          :disabled="userIsPrimary"
                          rules="required|customerName|max:30"
                          name="First Name"
                          id="firstName"
                          placeholder="Enter your first name"
                          v-model="reservation.firstName"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="required|customerName|max:30"
                          name="Last Name"
                          id="lastName"
                          placeholder="Enter your last name"
                          v-model="reservation.lastName"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="required|address|max:70"
                          name="Address 1"
                          id="street1"
                          placeholder="Enter your street address"
                          v-model="reservation.street1"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="address|max:70"
                          name="Address 2"
                          id="street2"
                          placeholder="Enter your street address, cont."
                          v-model="reservation.street2"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="required|city|max:58"
                          name="City"
                          id="city"
                          placeholder="Enter your city"
                          v-model="reservation.city"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <ValidationProvider
                          rules="required"
                          name="State"
                          v-slot="{ errors, ariaInput, ariaMsg }"
                        >
                          <label
                            @click="$refs.select.focus()"
                            :class="{ error: errors[0] }"
                            for="state"
                          >
                            State
                            <span class="error">*</span>
                          </label>
                          <select
                            class="form-control form-control-lg"
                            id="state"
                            v-model="reservation.state"
                            v-bind="ariaInput"
                          >
                            <option
                              v-for="(state, index) in states"
                              :key="index"
                              :value="state.abbreviation"
                              >{{ state.name }}</option
                            >
                          </select>
                          <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                            <li
                              v-for="(error, index) in errors"
                              :key="index"
                              class="error"
                              v-bind="ariaMsg"
                            >
                              {{ error }}
                            </li>
                          </ul>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="required|min:5|zipCode"
                          name="Zip Code"
                          id="zipCode"
                          placeholder="Enter your zip code"
                          v-model="reservation.zipCode"
                          mask="#####-####"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          type="email"
                          rules="required|email|max:256"
                          name="Email"
                          id="email"
                          placeholder="Enter your email address"
                          v-model="reservation.email"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="required|maskNumMin:10"
                          name="Phone"
                          id="phone"
                          placeholder="Enter your phone number"
                          v-model="reservation.phone"
                          mask="(###) ###-####"
                        />
                      </div>
                    </div>
                    <div class="form-row justify-content-center">
                      <div class="col col-12 form-group text-left">
                        <TextInput
                          rules="maskNumMin:10"
                          name="Alternate Phone"
                          id="alternatePhone"
                          placeholder="Enter your alternate phone number"
                          v-model="reservation.alternatePhone"
                          mask="(###) ###-####"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <div class="card-title my-2">Occupancy Information</div>
                </div>
                <div class="card-body">
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <ValidationProvider
                        rules="required"
                        name="Number Of People"
                        v-slot="{ errors, ariaInput, ariaMsg }"
                      >
                        <label
                          @click="$refs.select.focus()"
                          :class="{ error: errors[0] }"
                          for="numberOfPeople"
                        >
                          Number Of People
                          <span class="error">*</span>
                        </label>
                        <select
                          class="form-control form-control-lg"
                          id="numberOfPeople"
                          v-model="reservation.numberOfPeople"
                          v-bind="ariaInput"
                        >
                          <option
                            v-for="(num, index) in peopleNumbers"
                            :key="index"
                            :value="num"
                            >{{ num }}</option
                          >
                        </select>
                        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                          <li
                            v-for="(error, index) in errors"
                            :key="index"
                            class="error"
                            v-bind="ariaMsg"
                          >
                            {{ error }}
                          </li>
                        </ul>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <ValidationProvider
                        rules="required"
                        name="Number Of Vehicles"
                        v-slot="{ errors, ariaInput, ariaMsg }"
                      >
                        <label
                          @click="$refs.select.focus()"
                          :class="{ error: errors[0] }"
                          for="numberOfVehicles"
                        >
                          Number Of Vehicles
                          <span class="error">*</span>
                        </label>
                        <select
                          class="form-control form-control-lg"
                          id="numberOfVehicles"
                          v-model="reservation.numberOfVehicles"
                          v-bind="ariaInput"
                        >
                          <option
                            v-for="(num, index) in vehicleNumbers"
                            :key="index"
                            :value="num"
                            >{{ num }}</option
                          >
                        </select>
                        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                          <li
                            v-for="(error, index) in errors"
                            :key="index"
                            class="error"
                            v-bind="ariaMsg"
                          >
                            {{ error }}
                          </li>
                        </ul>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div
                    v-for="discount in discountClasses"
                    :key="discount.id"
                    class="form-row justify-content-center"
                  >
                    <div class="col col-12 form-group text-left">
                      <div @click="previousDiscountAlert">
                        <label>
                          <input
                            type="checkbox"
                            :value="discount.id"
                            :id="`discount_class_${discount.id}`"
                            v-model="reservation.discounts"
                            :disabled="disableDiscounts"
                          />
                          <div
                            style="position:absolute; left:0; right:0; top:0; bottom:0;"
                            v-if="disableDiscounts"
                          ></div>
                          {{ discount.name }}
                        </label>
                      </div>
                      <p v-html="discount.description"></p>
                    </div>
                  </div>
                  <p class="text-muted m-0">
                    Special rate/discount may be eligible for pass holders,
                    special group and membership holders. Proof of
                    age/membership may be required at the time of check in.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <div class="card-title my-2">Equipment Information</div>
                </div>
                <div class="card-body">
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <ValidationProvider
                        rules="required"
                        name="Equipment Type"
                        v-slot="{ errors, ariaInput, ariaMsg }"
                      >
                        <label
                          @click="$refs.select.focus()"
                          :class="{ error: errors[0] }"
                          for="equipmentType"
                        >
                          Equipment Type
                          <span class="error">*</span>
                        </label>
                        <select
                          class="form-control form-control-lg"
                          id="equipmentType"
                          v-model="reservation.equipmentType"
                          v-bind="ariaInput"
                        >
                          <option
                            v-for="spotEquipmentType in reservation.spot
                              .spotEquipmentTypes"
                            :key="spotEquipmentType.equipmentType.id"
                            :value="spotEquipmentType.equipmentType.id"
                            >{{ spotEquipmentType.equipmentType.name }}</option
                          >
                        </select>
                        <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                          <li
                            v-for="(error, index) in errors"
                            :key="index"
                            class="error"
                            v-bind="ariaMsg"
                          >
                            {{ error }}
                          </li>
                        </ul>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-12 form-group text-left">
                      <TextInput
                        :rules="
                          `${
                            reservation.equipmentType !== 1 ? 'required' : ''
                          }|numeric|between:0,${maxVehicleLength}`
                        "
                        name="Equipment Length (ft)"
                        id="equipmentLength"
                        placeholder="Enter length of equipment"
                        v-model="reservation.equipmentLength"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <PurchaseAddOns
                @updated="addOnsUpdated"
                :addOns="reservation.addOns"
              /> -->
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-header">
                  <div class="card-title my-2">
                    Reservation Site Information
                  </div>
                </div>
                <div class="card-body">
                  <div class="form-row justify-content-center">
                    <div class="col col-6 form-group text-left">
                      <div class="info-label">Park</div>
                    </div>
                    <div class="col col-6 form-group text-right">
                      <div class="info-label">
                        {{ reservation.locationName }}
                      </div>
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-6 form-group text-left">
                      <div class="info-label">Spot</div>
                    </div>
                    <div class="col col-6 form-group text-right">
                      <div class="info-label">{{ reservation.spot.name }}</div>
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-6 form-group text-left">
                      <div class="info-label">Spot Type(s)</div>
                    </div>
                    <div class="col col-6 form-group text-right">
                      <div
                        v-for="spotSpotType in spotTypes"
                        :key="spotSpotType.spotType.id"
                        class="text-right m-0 info-label"
                      >
                        {{ spotSpotType.spotType.name }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-row justify-content-center"
                    v-if="reservation.spot.loop"
                  >
                    <div class="col col-6 form-group text-left">
                      <div class="info-label">Loop</div>
                    </div>
                    <div class="col col-6 form-group text-right">
                      <div class="info-label">
                        {{ reservation.spot.loop.name }}
                      </div>
                    </div>
                  </div>
                  <div class="form-row justify-content-center" v-if="!isDayUse">
                    <div class="col col-6 form-group text-left">
                      <div class="info-label">Number of Nights</div>
                    </div>
                    <div class="col col-6 form-group text-right">
                      <div class="info-label">
                        {{ reservation.numberOfNights }}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="form-row justify-content-center">
                    <div class="col col-6 form-group text-left">
                      <div class="info-label">Check In</div>
                    </div>
                    <div class="col col-6 form-group text-right">
                      <div class="info-label">
                        {{ reservation.startDate | formatDate("MM-DD-YYYY") }}
                        {{ reservation.spot.checkInTime }}
                      </div>
                    </div>
                  </div>
                  <div class="form-row justify-content-center">
                    <div class="col col-6 form-group text-left">
                      <div class="info-label">Check Out</div>
                    </div>
                    <div class="col col-6 form-group text-right">
                      <div class="info-label">
                        {{ reservation.endDate }}
                        {{ reservation.spot.checkOutTime }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Alert
                v-if="
                  reservation.spot.product &&
                    reservation.spot.product.productClassification
                      .applyMessageToWebsite
                "
                :alert="{
                  htmlMessage:
                    reservation.spot.product.productClassification.message,
                  type: 'alert-primary'
                }"
                class="mb-2"
              />
            </div>
          </div>

          <button class="btn btn-primary mr-2" type="submit">
            CONTINUE
          </button>

          <button class="btn btn-cancel mr-2" type="button" @click="goBack">
            BACK
          </button>

          <p class="mt-3">
            <span style="color: red;">*</span> Indicates a required field
          </p>
        </form>
      </ValidationObserver>
    </div>
    <b-modal
      id="confirmDiscountModal"
      title="Residency Required"
      no-stacking
      no-close-on-backdrop
      hide-header-close
      no-close-on-esc
    >
      <p class="my-4">
        Special Discounted rates are applicable for Illinois residents only.
        Based on your profile, you are not eligible for this discount. If you
        think you are eligible, then update your address under profile to avail
        discounted rates. Identification card with Address is required at
        check-in.
      </p>
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn" variant="primary" @click="discardAndGoToProfile"
          >Discard Reservation and Go to Profile</b-button
        >
        <b-button
          class="btn"
          variant="secondary"
          @click="continueReservation(ok)"
          >Continue With Reservation</b-button
        >
      </template>
    </b-modal>
    <b-modal
      id="disabledDiscountModal"
      title="Discount Disabled"
      no-stacking
      no-close-on-backdrop
      hide-header-close
      no-close-on-esc
      centered
    >
      <p class="my-4">
        {{
          this.disableDiscountsResponse.discountedReservationInCart
            ? "Your cart includes multiple discounted reservation for the same dates, please limit only one reservation with discount for reservation dates and reprocess your requests."
            : "Your cart includes discounted reservation for the same dates of reservation that exists already on your profile. Please limit only one reservation with discount for reservation dates and reprocess your requests."
        }}
      </p>
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn" variant="primary" @click="ok">Ok</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import LocationService from "@/services/LocationService.js";
import ProfileService from "@/services/ProfileService.js";
import TenantService from "@/services/TenantService.js";
import SpotReservationService from "@/services/SpotReservationService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import states from "@/store/modules/states.js";
// import PurchaseAddOns from "@/components/reservation/PurchaseAddOns.vue";
import LocationAlert from "@/components/alert/LocationAlert.vue";
import moment from "moment";
import Alert from "@/components/alert/Alert.vue";

export default {
  name: "CreateReservation",
  title: "Create Reservation",
  data() {
    return {
      reservation: {
        spot: {},
        locationUseFee: {
          resident: 60,
          nonResident: 75
        },
        firstName: "",
        lastName: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        zipCode: "",
        email: "",
        phone: "",
        alternatePhone: "",
        numberOfPeople: "",
        numberOfVehicles: "",
        equipmentType: "",
        equipmentLength: "",
        discounts: [],
        addOns: {},
        customerId: null,
        isCustomerPrimaryOccupant: true
      },
      loggedInCustomer: {
        firstName: "Loading..."
      },
      equipmentTypes: [],
      userIsPrimary: true,
      discountAlertShown: false,
      errors: [],
      discountClasses: [],
      customerLoading: true,
      disableDiscounts: false,
      disableDiscountsResponse: {}
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    TextInput,
    FormErrorAlert,
    // PurchaseAddOns,
    LocationAlert,
    Alert
  },
  watch: {
    userIsPrimary() {
      if (this.userIsPrimary) {
        this.reservation = {
          ...this.reservation,
          firstName: this.loggedInCustomer.firstName,
          lastName: this.loggedInCustomer.lastName,
          street1: this.loggedInCustomer.street1,
          street2: this.loggedInCustomer.street2,
          city: this.loggedInCustomer.city,
          state: this.loggedInCustomer.state.trim(),
          zipCode: this.loggedInCustomer.zipCode,
          email: this.loggedInCustomer.email,
          phone: this.loggedInCustomer.phone,
          alternatePhone: this.loggedInCustomer.alternatePhone,
          isCustomerPrimaryOccupant: true
        };
      } else {
        this.reservation = {
          ...this.reservation,
          firstName: "",
          lastName: "",
          street1: "",
          street2: "",
          city: "",
          state: "",
          zipCode: "",
          email: "",
          phone: "",
          alternatePhone: "",
          discounts: [],
          isCustomerPrimaryOccupant: false
        };
      }
    }
  },
  methods: {
    goBack() {
      this.$store.commit("transaction/setReservationInProgress", false);
      this.$router
        .push("/location/" + this.reservation.locationId)
        .catch(() => {});
    },
    previousDiscountAlert() {
      if (!this.disableDiscounts) {
        return;
      }

      this.$bvModal.show("disabledDiscountModal");
    },
    discardAndGoToProfile() {
      //Discard reservation
      const spotReservationService = new SpotReservationService(this.tenantId);
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      spotReservationService
        .releaseSpotClaim(claimData.spotId, claimData.lockCode)
        .then(response => {
          if (response.statusCode === "Success") {
            this.$store.dispatch("transaction/clearReservation");
          }
        });

      //Redirect to customer profile
      const externalUserId = this.$store.getters["auth/externalUserId"];
      this.$store.dispatch("transaction/clear");
      this.$router.push("/customer-profile/" + externalUserId).catch(() => {});
    },
    continueReservation(ok) {
      ok();
      this.reservation.discounts = [];
      this.submit();
    },
    onSubmit() {
      this.$refs.createReservationForm.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(
              this.$refs.createReservationForm.errors
            )
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.createReservationForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          this.verifyCustomerQualitiesForDiscounts().then(verified => {
            if (!verified && !this.discountAlertShown) {
              //Show modal
              this.$bvModal.show("confirmDiscountModal");
              this.discountAlertShown = true;
              return;
            }
            this.submit();
          });
        }
      });
    },
    submit() {
      try {
        this.$store.commit("auth/setLoading", true);
        this.$store.dispatch("transaction/setReservation", this.reservation);
        this.$store.commit("auth/setLoading", false);
        this.$router.push("/review-reservation").catch(() => {});
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
        this.$store.commit("auth/setLoading", false);
      }
    },

    async verifyCustomerQualitiesForDiscounts() {
      //Pass discounts to backend to verify which have residency requirement and if they've been met
      //let externalUserId = this.$store.getters["auth/externalUserId"];
      let customerDiscounts = {
        discounts: [...this.reservation.discounts]
      };
      let profileService = new ProfileService(this.tenantId);
      let response = await profileService.doesCustomerQualifyForDiscounts(
        this.reservation.customerId,
        customerDiscounts
      );
      if (response.statusCode == "Success" && response.data === true) {
        return response.data;
      }
      return false;
    },
    async previousCustomerDiscountCheck() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      try {
        const profileService = new ProfileService(tenantId);
        const response = await profileService.previousCustomerDiscountCheck(
          this.reservation.customerId,
          this.reservation.startDate,
          this.reservation.endDate
        );
        if (response?.statusCode == "Success") {
          this.disableDiscounts =
            response.data.discountedReservationInCart ||
            response.data.discountedReservationReserved;
          this.disableDiscountsResponse = response.data;
          if (this.disableDiscounts) {
            this.reservation.discounts = [];
            this.reservation.disableDiscounts = true;
          }
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "public"
          });
        }
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      }
    },
    async setCustomerAsPrimary() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const userId = this.$store.getters["auth/externalUserId"];
      try {
        const profileService = new ProfileService(tenantId);
        const customerResponse = await profileService.getCustomerByExternalUserId(
          userId
        );
        const customer = customerResponse.data;
        this.reservation.customerId = customer.id;
        const primaryAddress = customer.addresses.find(
          addr => addr.addressTypeId == 1
        );
        const primaryPhoneNumber = customer.customerPhoneNumbers.find(
          phone => phone.isPrimary
        );
        const secondaryPhoneNumber = customer.customerPhoneNumbers.find(
          phone => phone.phoneNumberTypeId == 2
        );
        this.loggedInCustomer = {
          firstName: customer.firstName,
          lastName: customer.lastName,
          street1: primaryAddress.street1,
          street2: primaryAddress.street2 ? primaryAddress.street2 : "",
          city: primaryAddress.city,
          state: primaryAddress.state.trim(),
          zipCode: primaryAddress.zipcode,
          email: customer.email,
          phone: primaryPhoneNumber.number,
          alternatePhone: secondaryPhoneNumber
            ? secondaryPhoneNumber.number
            : ""
        };
        this.reservation = {
          ...this.reservation,
          firstName: customer.firstName,
          lastName: customer.lastName,
          street1: primaryAddress.street1,
          street2: primaryAddress.street2 ? primaryAddress.street2 : "",
          city: primaryAddress.city,
          state: primaryAddress.state.trim(),
          zipCode: primaryAddress.zipcode,
          email: customer.email,
          phone: primaryPhoneNumber.number,
          discounts:
            customer.discountClassId !== null ? [customer.discountClassId] : [],
          alternatePhone: secondaryPhoneNumber
            ? secondaryPhoneNumber.number
            : ""
        };
      } catch (err) {
        this.userIsPrimary = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message:
            "Profile could not be retrieved. Please fill out information manually.",
          layer: "public"
        });
      } finally {
        this.customerLoading = false;
      }
    },
    async loadSpotDetails() {
      const locationService = new LocationService(this.tenantId);
      const response = await locationService.getSpotDetails(
        this.reservation.spotId
      );

      this.reservation.spot = response.spotDetails;
      this.reservation.spot.checkInTime = this.reservation.spot.attributes.find(
        x => x.name == "Check-in Time"
      ).value;
      this.reservation.spot.checkOutTime = this.reservation.spot.attributes.find(
        x => x.name == "Check-out Time"
      ).value;
    },
    loadReservation() {
      const reservation = this.$store.getters["transaction/reservation"];
      if (reservation.spot) {
        this.reservation = {
          ...reservation
        };
      } else {
        this.reservation = {
          locationName: reservation.locationName,
          locationId: reservation.locationId,
          spotId: reservation.spotId,
          lockCode: reservation.lockCode,
          lockedUntil: reservation.lockedUntil,
          startDate: reservation.startDate,
          endDate: reservation.endDate,
          adaRequirementAcknowledged: reservation.adaRequirementAcknowledged,
          ...this.reservation
        };
      }
      this.reservation.numberOfNights = this.getNumberOfNights();
    },
    async initializeReservation() {
      this.loadReservation();
      await this.loadSpotDetails();
      await this.setCustomerAsPrimary();
      await this.previousCustomerDiscountCheck();
    },
    getNumberOfNights() {
      return moment(this.reservation.endDate, "MM/DD/YYYY").diff(
        moment(this.reservation.startDate, "MM/DD/YYYY"),
        "days"
      );
    },
    async getDiscounts() {
      const tenantService = new TenantService(this.tenantId);
      const response = await tenantService.getDiscountClasses();
      this.discountClasses = response.data;
    },
    addOnsUpdated(addOnQuantities) {
      this.reservation.addOns = addOnQuantities;
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    states() {
      return states;
    },
    maxPeople() {
      if (!this.reservation.spot || !this.reservation.spot.attributes)
        return null;

      const attr = this.reservation.spot.attributes.find(
        x => x.name === "Maximum Number of People"
      );
      return attr ? attr.value : 0;
    },
    maxVehicles() {
      if (!this.reservation.spot || !this.reservation.spot.attributes)
        return null;

      const attr = this.reservation.spot.attributes.find(
        x => x.name === "Maximum Number of Vehicles"
      );
      return attr ? attr.value : 0;
    },
    maxVehicleLength() {
      if (!this.reservation.spot || !this.reservation.spot.attributes)
        return null;

      const attr = this.reservation.spot.attributes.find(
        x => x.name === "Maximum Vehicle Length"
      );

      return attr ? attr.value : 0;
    },
    peopleNumbers() {
      const peopleNumbers = [];
      for (let i = 1; i <= this.maxPeople; i++) {
        peopleNumbers.push(i);
      }
      return peopleNumbers;
    },
    vehicleNumbers() {
      const vehicleNumbers = [];
      for (let i = 0; i <= this.maxVehicles; i++) {
        vehicleNumbers.push(i);
      }
      return vehicleNumbers;
    },
    externalUserId() {
      return this.$store.getters["auth/externalUserId"];
    },
    spotTypes() {
      let spotTypes = this.reservation.spot.spotSpotTypes;
      spotTypes = spotTypes?.sort((a, b) =>
        a.spotType.name.trim() > b.spotType.name.trim()
          ? 1
          : a.spotType.name.trim() < b.spotType.name.trim()
          ? -1
          : 0
      );
      return spotTypes;
    },
    isDayUse() {
      if (!this.reservation.spot || !this.reservation.spot.product) return null;

      return this.reservation.spot.product.productType.bookingType.id === 2; //Day Use
    }
  },
  created() {
    this.initializeReservation();
    this.getDiscounts();
    this.$store.commit("auth/setLoading", false);
  }
};
</script>

<style scoped>
.card-header {
  background-color: #103648;
}
.card-title {
  color: white;
}
.info-label {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
}
.alert-header {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
  font-size: 1.3125rem;
}
</style>
